import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useSurveys from "../../hooks/useSurveys";
import { useAppSelector } from "../../store/hooks";
import { dateTimeFormat, timestampToDate } from "../../utils/constants";

const columns = [
  { id: "name", label: "Client Name" },
  { id: "title", label: "Title" },
  { id: "description", label: "Description" },
  { id: "createdAt", label: "Created Date" },
  { id: "actions", label: "Actions" },
];

export default function ProjectsPage() {
  const { getProjects } = useSurveys();
  const projects = useAppSelector((state) => state.data.projects);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Projects
        </Typography>
        <Link to="/add-project">
          <Button variant="contained" size="small">
            Add Project
          </Button>
        </Link>
      </Toolbar>
      <TableContainer
        sx={{
          height: "calc(100vh-250px)",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.projectId}>
                    <TableCell>{row.clientName}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell style={{ maxWidth: "250px" }}>
                      {row.description}
                    </TableCell>
                    <TableCell>
                      {dateTimeFormat.format(timestampToDate(row.createdAt))}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Project Details">
                        <Link to={`${row.projectId}`}>
                          <Button variant="outlined" size="small">
                            Details
                          </Button>
                        </Link>
                      </Tooltip>
                      {/* <Tooltip title="Surveys">
                        <Link to={`/projects/${row.projectId}/surveys`}>
                          <IconButton>
                            <Poll color="primary" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Update">
                        <Link to={`/update-project/${row.projectId}`}>
                          <IconButton>
                            <EditOutlined color="primary" />
                          </IconButton>
                        </Link>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
