import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import {
  IProject,
  ISurvey,
  Profile,
  setProjects,
  setSurveys,
} from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import useError from "./useError";
import { Edge, Node } from "@xyflow/react";
import useEmployees from "./useEmployees";

const useSurveys = () => {
  const { getEmployeeById } = useEmployees();
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { projects, surveys } = useAppSelector((state) => state.data);

  const getProjects = useCallback(async () => {
    // if (projects.length !== 0) return;

    dispatch(setShowBackdrop(true));
    try {
      const q = query(collection(db, "projects"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), projectId: e.id } as IProject)
      );
      dispatch(setProjects(data));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, surveys.length]);

  const getProjectById = useCallback(
    async (projectId: string) => {
      dispatch(setShowBackdrop(true));
      try {
        const docRef = doc(db, `/projects/${projectId}`);
        const docSnap = await getDoc(docRef);
        const data = { ...docSnap.data(), projectId: docSnap.id } as IProject;
        dispatch(setProjects([...projects, data]));
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, surveys]
  );

  const addProject = async (data: IProject) => {
    dispatch(setShowBackdrop(true));
    try {
      const result = await addDoc(collection(db, "projects"), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      dispatch(setProjects([...projects, { ...data, projectId: result.id }]));
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Project Added Successfully!",
          type: "success",
        })
      );
      return { ...data, projectId: result.id } as IProject;
    } catch (error) {
      handleError(error);
      return undefined;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const addSurvey = async (projectId: string, data: ISurvey) => {
    dispatch(setShowBackdrop(true));
    try {
      const result = await addDoc(
        collection(db, "projects", projectId, "surveys"),
        {
          ...data,
          status: "Active",
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }
      );
      dispatch(setSurveys([...surveys, { ...data, surveyId: result.id }]));
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Survey added successfully!",
          type: "success",
        })
      );
      return result.id;
    } catch (error) {
      handleError(error);
      return undefined;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateSurvey = async (
    projectId: string,
    surveyId: string,
    data: Partial<ISurvey>
  ) => {
    dispatch(setShowBackdrop(true));
    try {
      const questionOrder: Record<string, any> = {};
      if (data.connections?.nodes && data.connections?.edges) {
        const nodes = data.connections?.nodes as Node[];
        const edges = data.connections?.edges as Edge[];

        // Filter out group nodes
        const groups = nodes.filter((node) => node.type === "group");

        // Create a map of group ID to question node
        const groupQuestions: Record<string, Node> = {};
        groups.forEach((group) => {
          const questionNode = nodes.find(
            (node) => node.id === `${group.id}-1`
          );
          if (questionNode) {
            groupQuestions[group.id] = questionNode;
          }
        });

        // Create a map of question IDs to their associated option IDs
        const options: Record<string, string[]> = {};
        Object.values(groupQuestions).forEach((question) => {
          const baseId = question.id.split("-")[0];
          const allOptions = nodes
            .filter(
              (node) =>
                node.id !== baseId &&
                node.id.split("-")[0] === baseId &&
                node.id !== question.id
            )
            .map((node) => node.id);
          options[question.id] = allOptions;
        });

        // Find the first question(s) that are not a target in any edges
        const firstQuestion = Object.values(groupQuestions).find(
          (question) => !edges.some((edge) => edge.target === question.id)
        );

        const generateQuestionOrder = (node: Node) => {
          const opt = options[node.id] || [];
          const parentId = node.parentId || "";
          if (!questionOrder[parentId]) {
            questionOrder[parentId] = {};
          }
          if (opt.length !== 0) {
            const hasOptionData = opt.some((optionId) => {
              const edge = edges.find((e) => e.source === optionId);
              const targetNode = nodes.find((e) => e.id === edge?.target);
              return Boolean(targetNode?.parentId);
            });
            if (!hasOptionData) {
              questionOrder[parentId]["nooption"] = null;
            } else {
              opt.forEach((optionId) => {
                const optionNode = nodes.find((e) => e.id === optionId);
                const edge = edges.find((e) => e.source === optionId);
                const optionName = optionNode?.data.label as string;
                const targetNode = nodes.find((e) => e.id === edge?.target);
                questionOrder[parentId][optionName] =
                  targetNode?.parentId || null;
              });
            }
          } else {
            const edge = edges.find((e) => e.source === node.id);
            console.log("AAAA edge", edge);
            const targetNode = nodes.find((e) => e.id === edge?.target);
            console.log("AAAA targetNode", targetNode);
            questionOrder[parentId]["nooption"] = targetNode?.parentId || null;
          }
        };

        if (firstQuestion) {
          generateQuestionOrder(firstQuestion);
        }

        // Process remaining questions
        Object.keys(groupQuestions).forEach((groupId) => {
          if (groupId !== firstQuestion?.parentId) {
            const node = groupQuestions[groupId];
            if (node) {
              generateQuestionOrder(node);
            }
          }
        });
      }
      console.log("AAAA", questionOrder);
      await updateDoc(doc(db, `/projects/${projectId}/surveys/${surveyId}`), {
        ...data,
        ...(Object.keys(questionOrder).length === 0 ? {} : { questionOrder }),
        updatedAt: serverTimestamp(),
      });

      const updated = surveys.map((e) =>
        e.surveyId === surveyId ? { ...e, ...data } : e
      );
      dispatch(setSurveys(updated));
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Survey updated successfully!",
          type: "success",
        })
      );
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getAssignedSurveyors = async (
    projectId: string,
    surveyId: string
  ): Promise<Profile[]> => {
    dispatch(setShowBackdrop(true));
    try {
      const docRef = doc(db, `/assigned/${projectId}-${surveyId}`);
      const docSnap = await getDoc(docRef);
      const surveyors = (docSnap.data()?.["surveyors"] ?? []) as string[];

      const employees = await Promise.all(
        surveyors.map(async (s) => {
          const d = await getEmployeeById(s);
          return d;
        })
      );

      return employees.filter(
        (employee): employee is Profile => employee !== undefined
      );
    } catch (error) {
      handleError(error);
      return [];
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateSurveyors = async (
    projectId: string,
    surveyId: string,
    data: Profile[]
    // locationMap: Record<string, string[]>
  ) => {
    dispatch(setShowBackdrop(true));
    try {
      await setDoc(
        doc(db, `/assigned/${projectId}-${surveyId}`),
        {
          surveyors: data.map((e) => e.uid),
          // locations: locationMap,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      await updateSurvey(projectId, surveyId, {
        surveyors: data.map((e) => e.uid),
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getSurveys = useCallback(
    async (projectId: string) => {
      // if (surveys.length !== 0) return;

      dispatch(setShowBackdrop(true));
      try {
        const q = query(
          collection(db, "projects", projectId, "surveys"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(
          (e) => ({ ...e.data(), surveyId: e.id } as ISurvey)
        );
        dispatch(setSurveys(data));
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, surveys.length]
  );

  const getSurveyById = useCallback(
    async (projectId: string, surveyId: string) => {
      dispatch(setShowBackdrop(true));
      try {
        const docRef = doc(db, `/projects/${projectId}/surveys/${surveyId}`);
        const docSnap = await getDoc(docRef);
        const data = { ...docSnap.data(), surveyId: docSnap.id } as ISurvey;
        dispatch(setSurveys([...surveys, data]));
        return data;
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, surveys]
  );

  return {
    getProjects,
    addProject,
    addSurvey,
    updateSurvey,
    getSurveys,
    getSurveyById,
    getProjectById,
    updateSurveyors,
    getAssignedSurveyors,
  };
};

export default useSurveys;
