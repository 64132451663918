import { Route, Routes } from "react-router-dom";
import AlertMessage from "./components/AlertMessage";
import DeleteDialog from "./components/deletedialog";
import Loader from "./components/loader";
import Navbar from "./components/navbar";
import AuthProvider from "./context/AuthContext";
import DashboardPage from "./pages/dashboard";
import EmployeesPage from "./pages/employees";
import LoginPage from "./pages/login";
import ProjectsPage from "./pages/projects";
import AddProjectPage from "./pages/projects/add-project";
import AddSurveyPage from "./pages/surveys/add-survey";
import SurveyQuestionsFlowPage from "./pages/surveys/survey-questions-flow";
import { useAppSelector } from "./store/hooks";
import ProjectDetailsPage from "./pages/projects/project-details";
import ResponsesPage from "./pages/responses";
import MapsPage from "./pages/maps";

function App() {
  const deleteDialog = useAppSelector((state) => state.ui.deleteDialog);

  return (
    <div>
      <Loader />
      <DeleteDialog open={deleteDialog.show} onDelete={deleteDialog.onDelete} />
      <AlertMessage />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/responses" element={<ResponsesPage />} />
            <Route path="/add-project" element={<AddProjectPage />} />
            <Route path="/update-project/:id" element={<AddProjectPage />} />
            <Route path="/projects/:project" element={<ProjectDetailsPage />} />
            <Route
              path="/projects/:project/surveys/add-survey"
              element={<AddSurveyPage />}
            />
            <Route
              path="/projects/:project/surveys/update-survey/:id"
              element={<AddSurveyPage />}
            />
            <Route
              path="/projects/:project/surveys/:id/questions-flow"
              element={<SurveyQuestionsFlowPage />}
            />
            <Route path="/maps" element={<MapsPage />} />
            <Route
              path="/projects/:project/map/:survey"
              element={<MapsPage />}
            />
          </Route>
          <Route path="login" element={<LoginPage />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
