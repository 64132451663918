import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { Profile, setEmployees } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setDeleteDialog,
  setShowBackdrop,
  setShowSnackbar,
} from "../store/ui.store";
import useError from "./useError";

const useEmployees = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { employees } = useAppSelector((state) => state.data);

  const getEmployeeByMobile = async (mobile: string) => {
    const existing = employees.find((e) => e.mobile === mobile);
    if (existing) return existing;
    dispatch(setShowBackdrop(true));
    try {
      const docRef = doc(db, "temp_employees", mobile);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const profile = docSnap.data() as Profile;
        dispatch(setEmployees([...employees, profile]));
      }
      return docSnap.exists();
    } catch (error) {
      handleError(error);
      return undefined;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const addEmployee = async (data: any) => {
    dispatch(setShowBackdrop(true));
    try {
      if (await getEmployeeByMobile(data.mobile)) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Employee already exist with this Mobile Number",
            type: "error",
          })
        );
        return;
      }

      await setDoc(doc(collection(db, "temp_employees"), data.mobile), {
        ...data,
        loggedIn: false,
        isActive: true,
        createdAt: serverTimestamp(),
      });
      dispatch(
        setEmployees([
          ...employees,
          { ...data, uid: data.mobile, loggedIn: false },
        ])
      );
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Employee added successfully!",
          type: "success",
        })
      );
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateEmployee = async (uid: string, data: Partial<Profile>) => {
    dispatch(setShowBackdrop(true));
    try {
      await updateDoc(doc(db, `/employees/${uid}`), {
        ...data,
        updatedAt: serverTimestamp(),
      });

      const updated = employees.map((e) =>
        e.uid === uid ? { ...e, ...data } : e
      );
      dispatch(setEmployees(updated));

      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getEmployees = useCallback(async () => {
    if (employees.length !== 0) return;

    dispatch(setShowBackdrop(true));
    try {
      const q = query(
        collection(db, "employees"),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), uid: e.id, loggedIn: true } as Profile)
      );

      const q1 = query(
        collection(db, "temp_employees"),
        where("loggedIn", "==", false),
        orderBy("createdAt", "desc")
      );
      const querySnapshot1 = await getDocs(q1);
      const data1 = querySnapshot1.docs.map(
        (e) => ({ ...e.data(), uid: e.id } as Profile)
      );

      dispatch(setEmployees([...data, ...data1]));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees.length]);

  const deleteEmployee = async (uid: string) => {
    dispatch(setShowBackdrop(true));
    try {
      await deleteDoc(doc(db, `/employees/${uid}`));
      await deleteDoc(doc(db, `/temp_employees/${uid}`));

      dispatch(setDeleteDialog({ show: false }));
      dispatch(setEmployees(employees.filter((e) => e.uid !== uid)));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getEmployeeById = useCallback(
    async (uid: string) => {
      try {
        const docRef = doc(db, `/employees/${uid}`);
        const docSnap = await getDoc(docRef);
        const employee = { ...docSnap.data(), uid: docSnap.id } as Profile;
        return employee;
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return {
    addEmployee,
    getEmployees,
    updateEmployee,
    deleteEmployee,
    getEmployeeById,
  };
};
export default useEmployees;
