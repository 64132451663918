import { doc, getDoc } from "firebase/firestore";
import { useCallback } from "react";
import * as XLSX from "xlsx";
import { db } from "../firebase";
import {
  ISurveyResponse,
  Profile,
  setSurveyResponses,
} from "../store/data.store";
import { useAppDispatch } from "../store/hooks";
import { setShowBackdrop } from "../store/ui.store";
import useError from "./useError";
import useSurveyResponse from "./useSurveyResponse";
import useSurveys from "./useSurveys";

const useReports = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();
  const { getSurveyById } = useSurveys();
  const { getResponses } = useSurveyResponse();

  const getResponseSurveyors = useCallback(
    async (
      project: string,
      survey: string,
      startDate: string,
      endDate: string,
      isReport = true
    ) => {
      dispatch(setShowBackdrop(true));
      try {
        const docRef = doc(db, `/responses/${project}-${survey}`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data() as Record<string, any>;

        if (!data) {
          console.warn("No data found for the specified project and survey.");
          return;
        }

        const surveyorIds = new Set<string>();

        Object.entries(data).forEach(([key, value]) => {
          if (key !== "total") {
            Object.keys(value).forEach((val) => surveyorIds.add(val));
          }
        });

        const uniqueIds = Array.from(surveyorIds);
        await generateReport(
          project,
          survey,
          uniqueIds,
          startDate,
          endDate,
          isReport
        );
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const generateReport = async (
    project: string,
    survey: string,
    ids: string[],
    startDate: string,
    endDate: string,
    isReport: boolean
  ) => {
    const data = await getSurveyById(project, survey);
    if (!data) return;

    const { questions } = data;
    const finalData: {
      surveyor: string;
      surveyorMobile: string;
      responseId: string;
      startTime: string;
      endTime: string;
      duration: string;
      coordinates: string;
      [key: string]: string;
    }[] = [];

    const finalResponses: (ISurveyResponse & Profile)[] = [];

    const headers = [
      "surveyor",
      "surveyorMobile",
      "responseId",
      "startTime",
      "endTime",
      "duration",
      "coordinates",
    ];

    questions.forEach((e) => {
      headers.push(e.question);
    });

    const processResponses = (responses: (ISurveyResponse & Profile)[]) => {
      responses?.forEach((res) => {
        const startTime = res.duration.startTime.toDate();
        const endTime = res.duration.endTime.toDate();
        const duration = calculateDuration(startTime, endTime);
        const answers = res.answers.reduce((acc, e) => {
          const questionText =
            questions.find((q) => q.questionId === e.questionId)?.question ||
            "";
          const answerValue =
            e.answer.length !== 0 ? e.answer : e.options.join(",");
          return { ...acc, [questionText]: answerValue };
        }, {});

        finalData.push({
          surveyor: `${res.firstName} ${res.lastName}`,
          surveyorMobile: res.mobile,
          responseId: res.docId || "",
          startTime: startTime.toISOString(), // Convert to ISO string
          endTime: endTime.toISOString(), // Convert to ISO string
          duration,
          coordinates: res.coordinates
            ? `${res.coordinates.latitude}-${res.coordinates.longitude}`
            : "-",
          ...answers,
        });
      });
    };

    const calculateDuration = (start: Date, end: Date) => {
      const diffMs = end.getTime() - start.getTime();
      const diffSecs = Math.floor(diffMs / 1000);
      const diffMins = Math.floor(diffSecs / 60);
      const remainingSecs = diffSecs % 60;
      return `${diffMins} mins ${remainingSecs} secs`;
    };

    await Promise.all(
      ids.map(async (id) => {
        const responses = await getResponses(
          project,
          survey,
          id,
          startDate,
          endDate
        );
        if (responses) {
          finalResponses.push(...responses);
          processResponses(responses);
        }
      })
    );
    dispatch(setSurveyResponses(finalResponses));
    if (isReport) {
      // Generate Excel file
      const worksheet = XLSX.utils.json_to_sheet(finalData, {
        header: headers,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

      // Generate buffer
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the buffer
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = `report-${startDate}-${endDate}.xlsx`;

      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
    }
  };

  return { getResponseSurveyors };
};

export default useReports;
