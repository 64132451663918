import { Box, Button, Grid, InputAdornment } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import InputField from "../../components/inputField";
import useSurveys from "../../hooks/useSurveys";
import { IProject } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";
import { useState } from "react";

const AddProjectPage = () => {
  const { id } = useParams();
  const { addProject } = useSurveys();

  const [showSurveyBtn, setShowSurveyButton] = useState<IProject | undefined>();

  const project = useAppSelector((state) =>
    state.data.projects.find((e) => e.projectId === id)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProject>({
    defaultValues: {
      title: project?.title,
      description: project?.description,
      clientName: project?.clientName,
      clientMobile: project?.clientMobile,
      clientAddress: project?.clientAddress,
    },
  });

  const onSubmit: SubmitHandler<IProject> = async (data) => {
    console.log(data);
    const result = await addProject(data);
    setShowSurveyButton(result);
  };

  return (
    <Box>
      <h2>Project Details</h2>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6}>
            <InputField
              id="title"
              label="Title"
              margin="dense"
              fullWidth
              {...register("title", { required: true })}
              required
              variant="outlined"
              error={!!errors.title}
              disabled={!!showSurveyBtn}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="description"
              label="Description"
              margin="dense"
              fullWidth
              {...register("description")}
              variant="outlined"
              disabled={!!showSurveyBtn}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="clientName"
              label="Client Name"
              margin="dense"
              fullWidth
              {...register("clientName", { required: true })}
              required
              variant="outlined"
              error={!!errors.clientName}
              disabled={!!showSurveyBtn}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="clientMobile"
              label="Client Mobile"
              fullWidth
              margin="normal"
              {...register("clientMobile", { required: true, maxLength: 10 })}
              required
              variant="outlined"
              inputProps={{ maxLength: 10 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              error={!!errors.clientMobile}
              disabled={!!showSurveyBtn}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="clientAddress"
              label="Client Address"
              margin="dense"
              fullWidth
              {...register("clientAddress", { required: true })}
              required
              variant="outlined"
              error={!!errors.clientAddress}
              disabled={!!showSurveyBtn}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          {showSurveyBtn ? (
            <Link
              to={`/projects/${showSurveyBtn.projectId}/surveys/add-survey`}
            >
              <Button size="large" variant="contained" type="button">
                Add Surveys
              </Button>
            </Link>
          ) : (
            <Button size="large" variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};
export default AddProjectPage;
