import { Box, Button } from "@mui/material";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  DefaultEdgeOptions,
  Edge,
  MiniMap,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSurveys from "../../hooks/useSurveys";
import { useAppSelector } from "../../store/hooks";

// const fitViewOptions: FitViewOptions = {
//   padding: 0.2,
// };

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
  style: { strokeWidth: 3, stroke: "black" },
};

const SurveyQuestionsFlowPage = () => {
  const { project, id } = useParams();
  const { updateSurvey, getSurveyById } = useSurveys();

  const survey = useAppSelector((state) =>
    state.data.surveys.find((e) => e.surveyId === id)
  );

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [isFirst, setIsFirst] = useState(true);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    if (project && id && !survey) {
      getSurveyById(project, id).then((val) => {
        if (val) {
          if (val.connections) {
            setNodes(val.connections.nodes);
            setEdges(val.connections.edges);
          } else {
            const groupExit: Node = {
              id: "exit-survey",
              type: "group",
              position: {
                x: 0,
                y: 200 * val.questions.length,
              },
              data: { label: "" },
              style: { width: 200, height: 50 },
              deletable: false,
              resizing: true,
            };
            const questionExit: Node = {
              id: "exit-survey-1",
              data: { label: "Exit Survey" },
              position: { x: 0, y: 20 },
              style: { background: "red", width: 200 },
              parentId: "exit-survey",
              extent: "parent",
              deletable: false,
              resizing: true,
              draggable: false,
            };
            const initialNodes: Node[] = [
              ...val.questions.map((item, index) => {
                const group: Node = {
                  id: item.questionId,
                  type: "group",
                  position: {
                    x: 0,
                    y: item.options.length === 0 ? 100 * index : 240 * index,
                  },
                  data: { label: "" },
                  style:
                    item.options.length === 0
                      ? { width: 800, height: 60 }
                      : { width: 800, height: 200 },
                  deletable: false,
                  resizing: true,
                };
                const question: Node = {
                  id: `${item.questionId}-${1}`,
                  data: { label: item.question },
                  position: { x: 300, y: 10 },
                  parentId: item.questionId,
                  extent: "parent",
                  deletable: false,
                  resizing: true,
                };
                const options: Node[] = item.options.map((option, i) => ({
                  id: `${item.questionId}-${i + 2}`,
                  data: { label: option },
                  position: { x: 200 * i + 10, y: 100 },
                  parentId: item.questionId,
                  extent: "parent",
                  deletable: false,
                  resizing: true,
                }));
                return [group, question, ...options];
              }),
              [groupExit, questionExit],
            ].flat();

            initialNodes.push(...[groupExit, questionExit]);
            const initialEdges: Edge[] = [
              ...val.questions.map((item, index) => {
                if (item.options.length === 0) return [];

                const options: Edge[] = item.options.map((option, i) => ({
                  id: `${item.questionId}-${option}`,
                  source: `${item.questionId}-1`,
                  target: `${item.questionId}-${i + 2}`,
                  animated: false,
                  deletable: false,
                }));

                return [...options];
              }),
            ].flat();

            setNodes(initialNodes);
            setEdges(initialEdges);
          }
        }
      });
    }
  }, [getSurveyById, id, project, survey]);

  useEffect(() => {
    if (survey && isFirst) {
      if (
        survey.connections.nodes.length === 0 &&
        survey.connections.edges.length === 0
      ) {
        const groupExit: Node = {
          id: "exit-survey",
          type: "group",
          position: {
            x: 0,
            y: 200 * survey.questions.length,
          },
          data: { label: "" },
          style: { width: 200, height: 50 },
          deletable: false,
          resizing: true,
        };
        const questionExit: Node = {
          id: "exit-survey-1",
          data: { label: "Exit Survey" },
          position: { x: 0, y: 20 },
          style: { background: "red", width: 200 },
          parentId: "exit-survey",
          extent: "parent",
          deletable: false,
          resizing: true,
          draggable: false,
        };
        const initialNodes: Node[] = survey.questions.flatMap((item, index) => {
          const group: Node = {
            id: item.questionId,
            type: "group",
            position: { x: 0, y: 200 * index },
            data: { label: item.question },
            style: { width: 800, height: 60 },
            deletable: false,
            resizing: true,
          };

          const question: Node = {
            id: `${item.questionId}-1`,
            data: { label: item.question },
            position: { x: 0, y: 20 },
            parentId: item.questionId,
            extent: "parent",
            draggable: false,
            deletable: false,
            resizing: true,
            style: {
              width: 800,
              fontWeight: "700",
              background: "green",
              color: "white",
            },
          };

          const options: Node[] = item.options.map((option, i) => ({
            id: `${item.questionId}-${i + 2}`,
            data: { label: option },
            position: { x: 200 * i + 10, y: 100 },
            parentId: item.questionId,
            extent: "parent",
            style: { background: "#39AFEA", fontWeight: "600" },
            draggable: false,
            deletable: false,
            resizing: true,
          }));

          return [group, question, ...options];
        });

        initialNodes.push(...[groupExit, questionExit]);

        const initialEdges = survey.questions.flatMap((item) =>
          item.options.length === 0
            ? []
            : item.options.map((option, i) => ({
                id: `${item.questionId}-${option}`,
                source: `${item.questionId}-1`,
                target: `${item.questionId}-${i + 2}`,
                animated: false,
                deletable: false,
              }))
        );

        setNodes(initialNodes);
        setEdges(initialEdges);
      } else {
        const initialNodes: Node[] = [...survey.connections.nodes];
        const initialEdges: Edge[] = [...survey.connections.edges];

        survey.questions.forEach((item, index) => {
          if (
            !Object.keys(survey.questionOrder ?? {})?.includes(item.questionId)
          ) {
            const group: Node = {
              id: item.questionId,
              type: "group",
              position: { x: 0, y: 200 * survey.questions.length },
              data: { label: item.question },
              style: { width: 800, height: 60 },
              deletable: false,
              resizing: true,
            };

            const question: Node = {
              id: `${item.questionId}-1`,
              data: { label: item.question },
              position: { x: 0, y: 20 },
              parentId: item.questionId,
              extent: "parent",
              draggable: false,
              deletable: false,
              resizing: true,
              style: {
                width: 800,
                fontWeight: "700",
                background: "green",
                color: "white",
              },
            };

            const options: Node[] = item.options.map((option, i) => ({
              id: `${item.questionId}-${i + 2}`,
              data: { label: option },
              position: { x: 200 * i + 10, y: 100 },
              parentId: item.questionId,
              extent: "parent",
              style: { background: "#39AFEA", fontWeight: "600" },
              draggable: false,
              deletable: false,
              resizing: true,
            }));
            initialNodes.push(...[group, question, ...options]);
            initialEdges.push(
              ...item.options.map((option, i) => ({
                id: `${item.questionId}-${option}`,
                source: `${item.questionId}-1`,
                target: `${item.questionId}-${i + 2}`,
                animated: false,
                deletable: false,
              }))
            );
          }
        });

        setNodes(initialNodes);
        setEdges(initialEdges);
      }
      setIsFirst(false);
    }
  }, [edges.length, isFirst, nodes.length, survey]);

  return (
    <div style={{ width: "100vw", height: "80vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        // fitView
        // fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
      >
        <Controls />
        <MiniMap />
        <Background gap={12} size={1} />
      </ReactFlow>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            if (project && id) {
              updateSurvey(project, id, { connections: { nodes, edges } });
            }
          }}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default SurveyQuestionsFlowPage;
