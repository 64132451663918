import places from "../../places.json";

import { Box, Button, MenuItem, Typography } from "@mui/material";
import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  GoogleMapApiLoader,
  InfoWindow,
  Marker,
  Polygon,
  Polyline,
} from "react-google-map-wrapper";
import { useParams } from "react-router-dom";
import InputField from "../../components/inputField";
import useEmployees from "../../hooks/useEmployees";
import useReports from "../../hooks/useReports";
import { ISurveyResponse, Profile } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";
import { COLORS, timestampToDateForDatePicker } from "../../utils/constants";

export interface Boundaries {
  type: string;
  crs: CRS;
  features: Feature[];
}

export interface CRS {
  type: string;
  properties: CRSProperties;
}

export interface CRSProperties {
  name: string;
}

export interface Feature {
  type: string;
  properties: FeatureProperties;
  geometry: Geometry;
}

export interface Geometry {
  type: string;
  coordinates: Array<Array<Array<number[] | number>>>;
}

export interface FeatureProperties {
  shapeName: string;
  shapeISO: string;
  shapeID: string;
  shapeGroup: string;
  shapeType: string;
  color: string;
}

const MapsPage = () => {
  const { project, survey } = useParams();
  const { getResponseSurveyors } = useReports();
  const { getEmployees } = useEmployees();

  const employees = useAppSelector((state) => state.data.employees);
  const data = useAppSelector((state) => state.data.surveyResponses);

  type GroupedData = {
    [key: string]: (ISurveyResponse & Profile)[];
  };

  const groupedData: GroupedData = data.reduce((acc, item) => {
    const { uid } = item;
    if (!acc[uid]) {
      acc[uid] = [];
    }
    acc[uid].push(item);
    return acc;
  }, {} as GroupedData);

  const [startDate, setStartDate] = useState(
    timestampToDateForDatePicker(new Date())
  );
  const [endDate, setEndDate] = useState(
    timestampToDateForDatePicker(new Date())
  );

  const [selectedArea, setSelectedArea] = useState<Feature | undefined>();
  const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 });

  const locations: Record<string, string> = {
    Aheri: "#f44336",
    Tumsar: "#e91e63",
    Katol: "#9c27b0",
    Hinganghat: "#673ab7",
    Pusad: "#3f51b5",
    Umarkhed: "#2196f3",
    Aurangabad: "#009688",
    Shahapur: "#ff9800",
    Sangole: "#ff5722",
    Nashik: "#607d8b",
    Thane: "#cddc39",
    Mumbai: "#4caf50",
  };

  useEffect(() => {
    if (employees.length === 0) {
      getEmployees();
    }
  }, [employees.length, getEmployees]);

  const [isOpen, setOpen] = useState<string | undefined>();

  const renderPolylineAndMarkers = useMemo(() => {
    return Object.keys(groupedData).map((grp, i) => {
      const color = COLORS[i];
      const filteredData = groupedData[grp].filter((f) => f.coordinates);

      const lines = filteredData.map((e) => ({
        lat: e.coordinates.latitude,
        lng: e.coordinates.longitude,
      }));

      return (
        <Fragment key={grp}>
          <Polyline
            path={lines}
            strokeColor={color}
            strokeOpacity={1.0}
            strokeWeight={5}
            geodesic
          />
          {filteredData.map((e) => (
            <InfoWindow
              key={e.docId}
              content={
                <>
                  <Typography>
                    {e.firstName} {e.lastName}
                  </Typography>
                  <Typography>{e.mobile}</Typography>
                </>
              }
              onCloseClick={() => setOpen(undefined)}
              open={isOpen === e.docId}
            >
              <Marker
                lat={e.coordinates.latitude}
                lng={e.coordinates.longitude}
                title={e.firstName}
                onClick={() => setOpen(e.docId)}
              ></Marker>
            </InfoWindow>
          ))}
        </Fragment>
      );
    });
  }, [groupedData, isOpen]);

  const polygons = useMemo(() => {
    if (!selectedArea) return null;
    return selectedArea.geometry.coordinates.map((polygon) =>
      (polygon.length === 1 ? polygon[0] : polygon).map((coord: any) => ({
        lat: coord[1],
        lng: coord[0],
      }))
    );
  }, [selectedArea]);

  return (
    <Suspense fallback={<>Loading...</>}>
      <>
        <Box sx={{ display: "flex", gap: 4, mb: 4, alignItems: "center" }}>
          <InputField
            label="Area"
            defaultValue=""
            margin="dense"
            fullWidth
            select
            required
            variant="outlined"
            onChange={(e) => {
              const place = (places as Boundaries).features.find(
                (f) => f.properties.shapeName === e.target.value
              );
              setSelectedArea(place);
              const point = place?.geometry.coordinates.map((polygon) => {
                return (polygon.length === 1 ? polygon[0] : polygon).map(
                  (coord: any) => {
                    return { lat: coord[1], lng: coord[0] };
                  }
                );
              });
              if (point) setCenter(point[0][0]);
            }}
          >
            {Object.keys(locations).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </InputField>
          <InputField
            id="startDate"
            label="Start Date"
            margin="dense"
            type="date"
            fullWidth
            defaultValue={startDate}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
          <InputField
            id="endDate"
            label="End Date"
            margin="dense"
            type="date"
            fullWidth
            defaultValue={endDate}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{ width: "300px" }}
            onClick={() => {
              if (survey && project)
                getResponseSurveyors(
                  project,
                  survey,
                  startDate,
                  endDate,
                  false
                );
            }}
          >
            Search
          </Button>
        </Box>
        <GoogleMapApiLoader
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
          suspense
        >
          <GoogleMap
            mapOptions={{
              mapId: "my-map",
            }}
            containerProps={{ id: "my-map" }}
            style={{ height: "80vh", width: "100%" }}
            zoom={10}
            center={center}
          >
            {selectedArea && (
              <Polygon
                key={selectedArea.properties.shapeID}
                paths={polygons}
                strokeColor="#f44336"
                strokeOpacity={1}
                strokeWeight={2}
                fillColor="#f44336"
                fillOpacity={0.3}
              />
            )}
            {renderPolylineAndMarkers}
          </GoogleMap>
        </GoogleMapApiLoader>
      </>
    </Suspense>
  );
};

export default MapsPage;
