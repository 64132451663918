import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import useSurveyResponse from "../../hooks/useSurveyResponse";
import useSurveys from "../../hooks/useSurveys";
import { ISurveyResponse, Profile } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";
import {
  dateFormat,
  dateTimeFormat,
  timestampToDateForDatePicker,
} from "../../utils/constants";

const columns = [
  { id: "date", label: "Date" },
  { id: "startTime", label: "Start Time" },
  { id: "endTime", label: "End Time" },
  { id: "coordinates", label: "Coordinates" },
  { id: "recording", label: "Recording" },
];

const ResponsesPage = () => {
  const { getProjects, getSurveys, getAssignedSurveyors } = useSurveys();
  const { getResponses } = useSurveyResponse();
  const projects = useAppSelector((state) => state.data.projects);
  const surveys = useAppSelector((state) => state.data.surveys);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [project, setProject] = useState<string | undefined>();
  const [survey, setSurvey] = useState<string | undefined>();
  const [employees, setEmployees] = useState<Profile[]>([]);
  const [employee, setEmployee] = useState<string | undefined>();
  const [responses, setResponses] = useState<ISurveyResponse[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        sx={{
          display: "flex",
          gap: 5,
          justifyContent: "space-between",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Survey Responses
        </Typography>
        <Box sx={{ display: "flex", gap: 3, justifyContent: "space-between" }}>
          <FormControl size="small">
            <InputLabel id="project-select">Project</InputLabel>
            <Select
              labelId="project-select"
              id="project"
              sx={{ width: "250px" }}
              value={project || ""}
              size="small"
              onChange={(e) => {
                setSurvey(undefined);
                setEmployee(undefined);
                setProject(e.target.value);
                getSurveys(e.target.value);
              }}
              label="Project"
            >
              {projects.map((p) => (
                <MenuItem key={p.projectId} value={p.projectId}>
                  {p.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel id="survey-select">Survey</InputLabel>
            <Select
              labelId="survey-select"
              id="survey"
              sx={{ width: "250px" }}
              value={survey || ""}
              size="small"
              variant="outlined"
              label="Survey"
              disabled={!project}
              onChange={async (e) => {
                setSurvey(e.target.value);
                if (project) {
                  const emps = await getAssignedSurveyors(
                    project,
                    e.target.value
                  );
                  setEmployees(emps);
                }
              }}
            >
              {surveys.map((s) => (
                <MenuItem key={s.surveyId} value={s.surveyId}>
                  {s.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel id="employee-select">Surveyor</InputLabel>
            <Select
              labelId="employee-select"
              id="employee"
              label="Surveyor"
              sx={{ width: "250px" }}
              value={employee || ""}
              size="small"
              variant="outlined"
              disabled={!survey}
              onChange={async (e) => {
                setEmployee(e.target.value);
                if (project && survey) {
                  const resp = await getResponses(
                    project,
                    survey,
                    e.target.value,
                    timestampToDateForDatePicker(new Date()),
                    timestampToDateForDatePicker(new Date())
                  );
                  if (resp) {
                    setResponses(resp);
                  }
                }
              }}
            >
              {employees.map((e) => (
                <MenuItem key={e.uid} value={e.uid}>
                  {e.firstName} {e.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
      <TableContainer
        sx={{
          height: "calc(100vh-250px)",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {responses
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`${row.createdAt.toString()}`}
                  >
                    <TableCell>
                      {dateFormat.format(row.createdAt.toDate())}
                    </TableCell>
                    <TableCell>
                      {dateTimeFormat.format(row.duration.startTime.toDate())}
                    </TableCell>
                    <TableCell>
                      {dateTimeFormat.format(row.duration.endTime.toDate())}
                    </TableCell>
                    <TableCell>
                      {row.coordinates?.latitude}, {row.coordinates?.longitude}
                    </TableCell>
                    {!row.recording ? (
                      <TableCell></TableCell>
                    ) : (
                      <TableCell>
                        <Button
                          onClick={() => {
                            // const a = new Audio(row.recording);
                            // a.play();
                          }}
                        >
                          Audio
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={responses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ResponsesPage;
