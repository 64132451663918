import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../inputField";
import useEmployees from "../../hooks/useEmployees";
import { ROLES } from "../../utils/constants";

interface AddEmployeeDialogProps {
  open?: boolean;
  onClose: () => void;
}

interface Form {
  mobile: string;
  role: string;
  employmentType: string;
}

const AddEmployeeDialog = ({ open, onClose }: AddEmployeeDialogProps) => {
  const { addEmployee } = useEmployees();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const result = await addEmployee(data);
    if (result) dialogClose();
  };

  const dialogClose = () => {
    reset();
    onClose();
  };

  return !open ? null : (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Add Employee</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <InputField
            id="mobile"
            label="Mobile"
            fullWidth
            margin="normal"
            {...register("mobile", { required: true, maxLength: 10 })}
            required
            variant="outlined"
            inputProps={{ maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
            error={!!errors.mobile}
          />
          <InputField
            id="role"
            label="Role"
            fullWidth
            {...register("role", { required: true })}
            required
            variant="outlined"
            defaultValue=""
            margin="normal"
            error={!!errors.role}
            select
          >
            {ROLES.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </InputField>
          <InputField
            id="employmentType"
            label="Employment Type"
            fullWidth
            {...register("employmentType", { required: true })}
            required
            variant="outlined"
            defaultValue=""
            margin="normal"
            error={!!errors.employmentType}
            select
          >
            {["Employee", "Contractor"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </InputField>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEmployeeDialog;
