import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import InputField from "../inputField";
import { useState } from "react";
import { timestampToDateForDatePicker } from "../../utils/constants";

interface FilterDatesDialogProps {
  open?: boolean;
  onClose: () => void;
  onSubmit: (startDate: string, endDate: string) => void;
}

const FilterDatesDialog = ({
  open,
  onClose,
  onSubmit,
}: FilterDatesDialogProps) => {
  const [startDate, setStartDate] = useState(
    timestampToDateForDatePicker(new Date())
  );
  const [endDate, setEndDate] = useState(
    timestampToDateForDatePicker(new Date())
  );

  const dialogClose = () => {
    onClose();
  };

  return !open ? null : (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent dividers>
        <InputField
          id="startDate"
          label="Start Date"
          margin="dense"
          type="date"
          fullWidth
          defaultValue={startDate}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <InputField
          id="endDate"
          label="End Date"
          margin="dense"
          type="date"
          fullWidth
          defaultValue={endDate}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSubmit(startDate, endDate);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FilterDatesDialog;
