import { Timestamp } from "firebase/firestore";

export function serializeObject(obj: any): any {
  // If the object is undefined, return null
  if (obj === undefined) {
    return null;
  }
  // If the object is not of type 'object' or is null, return the object as is
  if (
    typeof obj !== "object" ||
    obj === null ||
    obj === undefined ||
    obj === ""
  ) {
    return obj;
  }
  // If the object is an array, recursively serialize each element
  if (Array.isArray(obj)) {
    return obj.map((item) => serializeObject(item));
  }
  // If the object is a plain object, recursively serialize each property
  const result: { [key: string]: any } = {};
  // Iterate over each property of the object
  for (const key in obj) {
    // Check if the property is directly defined on the object (not inherited)
    if (Object.hasOwnProperty.call(obj, key)) {
      // Recursively serialize each property value
      result[key] = serializeObject(obj[key]);
    }
  }
  // Return the resulting serialized object
  return result;
}

export const dateFormat = new Intl.DateTimeFormat("en-IN", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export const dateTimeFormat = new Intl.DateTimeFormat("en-IN", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export const isDateGreaterThanToday = (givenDate: Date) => {
  // Get today's date without the time part
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Create a Date object for the given date
  const given = new Date(givenDate);
  given.setHours(0, 0, 0, 0);

  // Compare the dates
  return given < today;
};

export const QUESTION_TYPE = [
  // "SECTION",
  "SINGLE",
  "MULTI",
  "NUMBER",
  "TEXT",
] as const;

export const QUESTION_TYPE_MAP: Record<(typeof QUESTION_TYPE)[number], string> =
  {
    TEXT: "Text",
    NUMBER: "Number",
    SINGLE: "Single Choice",
    MULTI: "Multi Choice",
    // SECTION: "Section",
  };

export function timestampToDateForDatePicker(value?: any) {
  let timestamp = value;
  // Make sure timestamp is a Firestore Timestamp
  if (!timestamp || !timestamp.seconds) {
    timestamp =
      typeof value === "string"
        ? Timestamp.fromDate(new Date(value))
        : Timestamp.now();
  }

  const seconds = timestamp.seconds;
  const nanoseconds = timestamp.nanoseconds;

  // Create a new Date object using the seconds and nanoseconds
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  // Extract individual components of the date
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear().toString();

  // Format the date in YYYY-MM-DD format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function timestampToDate(value?: any) {
  // Make sure timestamp is a Firestore Timestamp
  if (value && value?.seconds) {
    const timestamp = value as Timestamp;
    // Create a new Date object using the seconds and nanoseconds
    const date = timestamp.toDate();

    return date;
  }
  return value as Date;
}

export const ROLES = [
  "Associate",
  "Lead Associate",
  "Operations Manager",
  "Operations Director",
] as const;

export const COLORS = [
  "#5C5C5C",
  "#1E1E1E",
  "#303030",
  "#424242",
  "#545454",
  "#666666",
  "#0F0F0F",
  "#212121",
  "#333333",
  "#454545",
  "#575757",
  "#171717",
  "#292929",
  "#3B3B3B",
  "#4D4D4D",
  "#5F5F5F",
  "#1F1F1F",
  "#313131",
  "#434343",
  "#555555",
  "#676767",
  "#60606C",
  "#262626",
  "#383838",
  "#4A4A4A",
  "#5C5C5C",
  "#6E6E6E",
  "#161616",
  "#282828",
  "#3A3A3A",
  "#4C4C4C",
  "#5E5E5E",
  "#202024",
  "#1A1A1D",
  "#2C2C34",
  "#3E3E46",
  "#4F4F59",
  "#323236",
  "#444448",
  "#56565A",
  "#68686C",
  "#181818",
  "#2A2A2A",
  "#3C3C3C",
  "#4E4E4E",
  "#606060",
  "#1C1C1C",
  "#2E2E2E",
  "#404040",
  "#525252",
  "#646464",
  "#212121",
  "#333333",
  "#454545",
  "#575757",
  "#696969",
  "#151515",
  "#272727",
  "#393939",
  "#4B4B4B",
  "#5D5D5D",
  "#1F1F1F",
  "#313131",
  "#434343",
  "#555555",
  "#676767",
  "#121212",
  "#242424",
  "#363636",
  "#484848",
  "#5A5A5A",
  "#191919",
  "#2B2B2B",
  "#3D3D3D",
  "#4F4F4F",
  "#616161",
  "#1B1B1B",
  "#2D2D2D",
  "#3F3F3F",
  "#515151",
  "#636363",
  "#111111",
  "#232323",
  "#353535",
  "#474747",
  "#595959",
  "#1D1D1D",
  "#2F2F2F",
  "#414141",
  "#535353",
  "#656565",
  "#141414",
  "#262626",
  "#383838",
  "#4A4A4A",
];
