import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { ISurveyResponse, Profile } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop } from "../store/ui.store";
import useError from "./useError";

const useSurveyResponse = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();
  const employees = useAppSelector((state) => state.data.employees);

  const getResponseNumbers = useCallback(
    async (project: string, survey: string) => {
      dispatch(setShowBackdrop(true));
      try {
        const docRef = doc(db, `/responses/${project}-${survey}`);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getResponses = useCallback(
    async (
      project: string,
      survey: string,
      uid: string,
      startDate: string,
      endDate: string
    ) => {
      dispatch(setShowBackdrop(true));
      try {
        const q = query(
          collection(db, `/responses/${project}-${survey}/${uid}`),
          where("createdAt", ">=", new Date(`${startDate}T00:00:00`)),
          where("createdAt", "<=", new Date(`${endDate}T23:59:59`)),
          orderBy("createdAt", "asc")
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(
          (e) =>
            ({
              ...e.data(),
              docId: e.id,
              ...employees.find((emp) => emp.uid === uid),
            } as ISurveyResponse & Profile)
        );
        return data;
      } catch (error) {
        console.error(error);
        handleError(error);
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return { getResponseNumbers, getResponses };
};

export default useSurveyResponse;
