import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import useEmployees from "../../hooks/useEmployees";
import useSurveys from "../../hooks/useSurveys";
import { Profile } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";

interface SurveyorsDialogProps {
  open: boolean;
  onClose: () => void;
  surveyorIds: string[];
  locations: string[];
  project?: string;
  surveyId?: string;
}

const SurveyorsDialog = ({
  open,
  onClose,
  surveyorIds,
  project,
  surveyId,
  locations,
}: SurveyorsDialogProps) => {
  const { getEmployees } = useEmployees();
  const { updateSurveyors } = useSurveys();

  const employees = useAppSelector((state) => state.data.employees);
  const [surveyors, setSurveyors] = useState<Profile[]>([]);
  // const [selected, setSelected] = useState<string>();
  // const [locationMap, setLocationMap] = useState<Record<string, string[]>>();

  useEffect(() => {
    if (open) {
      getEmployees();
    }
  }, [getEmployees, open]);

  // useEffect(() => {
  //   if (open && project && surveyId && !locationMap) {
  //     getAssignedSurveyors(project, surveyId).then((val) => {
  //       const mem = val?.["surveyors"] as any[];
  //       const temp: Record<string, string[]> = {};
  //       mem?.forEach((e) => {
  //         const data = val?.["locations"][e];
  //         temp[e] = data;
  //       });
  //       setLocationMap(temp);
  //     });
  //   }
  // }, [getAssignedSurveyors, locationMap, open, project, surveyId]);

  useEffect(() => {
    if (surveyors.length === 0 && employees.length > 0) {
      setSurveyors(employees.filter((e) => surveyorIds?.includes(e.uid)) ?? []);
    }
  }, [employees, getEmployees, open, surveyorIds, surveyors.length]);

  return !open ? null : (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Surveyors</DialogTitle>
      <DialogContent dividers>
        {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <List>
              {surveyors.map((e) => (
                <ListItem key={e.uid} disablePadding>
                  <ListItemButton
                    onClick={() => setSelected(e.uid)}
                    selected={e.uid === selected}
                  >
                    <ListItemIcon>
                      <Inbox />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${e.firstName} ${e.lastName}`}
                      secondary={`${e.mobile} (${e.role})`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            {selected && (
              <FormGroup>
                {locations.map((location) => (
                  <FormControlLabel
                    key={location}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            const prev = locationMap?.[selected] ?? [];
                            setLocationMap({
                              [selected]: [...prev, location],
                            });
                          } else {
                            const prev = locationMap?.[selected] ?? [];
                            setLocationMap({
                              [selected]: prev.filter((p) => p !== location),
                            });
                          }
                        }}
                        checked={
                          locationMap?.[selected]?.includes(location) ?? false
                        }
                      />
                    }
                    label={location}
                  />
                ))}
              </FormGroup>
            )}
          </Grid>
        </Grid> */}

        <Autocomplete
          multiple
          id="Associates"
          options={employees.filter((e) => e.loggedIn !== false)}
          value={surveyors}
          disableCloseOnSelect
          getOptionLabel={(option) => `${option.firstName}-${option.mobile}`}
          onChange={(_, newValue) => {
            setSurveyors(newValue);
          }}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox checked={selected} />
                {`${option.firstName}-${option.mobile}`}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Associates"
              placeholder="Select Associates"
              size="small"
              margin="dense"
            />
          )}
        />
        {/* <Stack direction="row" spacing={1}>
          {surveyors.map((surveyor) => (
            <Chip
              key={surveyor.uid}
              label={`${surveyor.firstName}-${surveyor.mobile}`}
              variant="outlined"
              onDelete={() => {
                //
              }}
            />
          ))}
        </Stack> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={async () => {
            if (project && surveyId) {
              const result = await updateSurveyors(
                project,
                surveyId,
                surveyors
              );
              if (result) onClose();
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurveyorsDialog;
