import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { Profile, setProfile } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import useError from "./useError";

const useProfile = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { user, profile } = useAppSelector((state) => state.data);

  const getProfile = useCallback(async () => {
    if (profile) return;
    try {
      dispatch(setShowBackdrop(true));
      const path = `/employees/${user?.uid}`;
      const snap = await getDoc(doc(db, path));
      const data = { ...snap.data(), uid: snap.id } as Profile;
      dispatch(setProfile(data));
    } catch (error) {
      handleError(error, "getProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, profile, user?.tenantId, user?.uid]);

  const updateProfile = async (data: Partial<Profile>) => {
    try {
      dispatch(setShowBackdrop(true));
      const path = `/employees/${user?.uid}`;
      await updateDoc(doc(db, path), {
        ...data,
        updatedAt: serverTimestamp(),
      });
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Profile updated successfully!",
          type: "success",
        })
      );
    } catch (error) {
      handleError(error, "updateProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { getProfile, updateProfile };
};
export default useProfile;
