import { Link, useParams } from "react-router-dom";
import useSurveys from "../../hooks/useSurveys";
import { useAppSelector } from "../../store/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  dateFormat,
  dateTimeFormat,
  timestampToDate,
} from "../../utils/constants";
import SurveyorsDialog from "../../components/surveyors-dialog";
import FilterDatesDialog from "../../components/filter-dates-dialog";
import useReports from "../../hooks/useReports";
import useEmployees from "../../hooks/useEmployees";

const columns = [
  { id: "title", label: "Title" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "createdAt", label: "Created Date" },
  { id: "actions", label: "Action" },
];

const ProjectDetailsPage = () => {
  const { project } = useParams();
  const { getProjectById, getSurveys } = useSurveys();
  const { getResponseSurveyors } = useReports();
  const { getEmployees } = useEmployees();

  const details = useAppSelector((state) =>
    state.data.projects.find((e) => e.projectId === project)
  );
  const surveys = useAppSelector((state) => state.data.surveys);
  const employees = useAppSelector((state) => state.data.employees);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openSurveyors, setOpenSurveyors] = useState<{
    open: boolean;
    surveyorIds: string[];
    project?: string;
    surveyId?: string;
    locations: string[];
  }>({ open: false, surveyorIds: [], locations: [] });

  const [showFilterDialog, setShowFilterDialog] = useState<
    string | undefined
  >();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (project && !details) {
      getProjectById(project);
    }
  }, [details, getProjectById, project]);

  useEffect(() => {
    if (project) {
      getSurveys(project);
    }
  }, [getSurveys, project]);

  useEffect(() => {
    if (employees.length === 0) {
      getEmployees();
    }
  }, [employees.length, getEmployees]);

  return !details ? null : (
    <Box>
      <h2>Project Details</h2>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ fontWeight: "600", color: "#014874" }}>Project Name: </Box>
        <Box sx={{ fontWeight: "500" }}>{details.title}</Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ fontWeight: "600", color: "#014874" }}>Description: </Box>
        <Box sx={{ fontWeight: "500" }}>{details.description}</Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ fontWeight: "600", color: "#014874" }}>Client Name: </Box>
        <Box sx={{ fontWeight: "500" }}>{details.clientName}</Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ fontWeight: "600", color: "#014874" }}>Client Mobile: </Box>
        <Box sx={{ fontWeight: "500" }}>{details.clientMobile}</Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ fontWeight: "600", color: "#014874" }}>Client Address: </Box>
        <Box sx={{ fontWeight: "500" }}>{details.clientAddress}</Box>
      </Box>
      <Paper sx={{ width: "100%", marginTop: "2rem", overflow: "hidden" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Surveys
          </Typography>
          <Link to="surveys/add-survey">
            <Button variant="contained" size="small">
              Add Survey
            </Button>
          </Link>
        </Toolbar>
        {openSurveyors.open && (
          <SurveyorsDialog
            open={openSurveyors.open}
            surveyorIds={openSurveyors.surveyorIds}
            locations={openSurveyors.locations}
            project={openSurveyors.project}
            surveyId={openSurveyors.surveyId}
            onClose={() =>
              setOpenSurveyors({ open: false, surveyorIds: [], locations: [] })
            }
          />
        )}
        {showFilterDialog && (
          <FilterDatesDialog
            open={!!showFilterDialog}
            onClose={() => {
              setShowFilterDialog(undefined);
            }}
            onSubmit={(startDate, endDate) => {
              setShowFilterDialog(undefined);
              if (project) {
                getResponseSurveyors(
                  project,
                  showFilterDialog,
                  startDate,
                  endDate
                );
              }
            }}
          />
        )}
        <TableContainer
          sx={{
            height: "calc(100vh-250px)",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.surveyId}>
                      <TableCell>
                        <Tooltip title={row.description}>
                          <span>{row.title}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {dateFormat.format(timestampToDate(row.startDate))}
                      </TableCell>
                      <TableCell>
                        {dateFormat.format(timestampToDate(row.endDate))}
                      </TableCell>
                      <TableCell>
                        {dateTimeFormat.format(timestampToDate(row.createdAt))}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Tooltip title="Surveyors List">
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setOpenSurveyors({
                                  open: true,
                                  surveyorIds: row.surveyors,
                                  project: project,
                                  surveyId: row.surveyId,
                                  locations: row.locations,
                                });
                              }}
                            >
                              Surveyors
                            </Button>
                          </Tooltip>
                          <Tooltip title="Update">
                            <Link to={`surveys/update-survey/${row.surveyId}`}>
                              <Button variant="outlined" size="small">
                                Details
                              </Button>
                            </Link>
                          </Tooltip>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setShowFilterDialog(row.surveyId);
                            }}
                          >
                            Download Report
                          </Button>
                          {row.surveyId === "K2yoSyPS9OJo1w0Lgw0p" && (
                            <Link to={`map/${row.surveyId}`}>
                              <Button variant="outlined" size="small">
                                Map
                              </Button>
                            </Link>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={surveys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ProjectDetailsPage;
